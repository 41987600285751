"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = ["href"];
const _hoisted_2 = ["src"];
const _hoisted_3 = ["href"];
const vue_3 = require("vue");
const vuex_1 = require("vuex");
const toastStore_1 = require("../../stores/toast/toastStore");
exports.default = (0, vue_1.defineComponent)({
    __name: 'HighlightedNewsletters',
    setup(__props) {
        const store = (0, vuex_1.useStore)();
        const toastStore = (0, toastStore_1.useToastStore)();
        const highlightedNewsletters = (0, vue_3.computed)(() => store.getters["newsletters/highlightedNewsletters"]);
        (0, vue_3.onBeforeMount)(() => {
            store.dispatch("newsletters/getHighlightedNewsletters", {
                page: 1,
                itemsPerPage: 10,
                "order[id]": 'desc'
            })
                .catch((error) => { toastStore.addErrorToast(error.toString()); });
        });
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)((0, vue_2.unref)(highlightedNewsletters), (newsletter) => {
                return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                    class: "p-2 bg-white shadow mb-7",
                    key: newsletter.id
                }, [
                    (0, vue_2.createElementVNode)("a", {
                        class: "block",
                        href: newsletter.fileUrl,
                        target: "_blank"
                    }, [
                        (0, vue_2.createElementVNode)("img", {
                            src: newsletter.thumbnailUrl
                        }, null, 8, _hoisted_2)
                    ], 8, _hoisted_1),
                    (0, vue_2.createElementVNode)("a", {
                        class: "block text-center font-bold",
                        href: newsletter.fileUrl,
                        target: "_blank"
                    }, (0, vue_2.toDisplayString)(newsletter.title), 9, _hoisted_3)
                ]));
            }), 128));
        };
    }
});
