"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getQueryParams = exports.spareWheelProductListFields = exports.motorcycleTyreProductListFields = exports.forkliftTyreProductListFields = exports.agriculturalAndIndustrialTyreProductListFields = exports.truckTyreProductListFields = exports.carTyreProductListFields = exports.tyreProductListFields = exports.spareWheelSearchForm = exports.motorcycleTyreSearchForm = exports.forkliftTyreSearchForm = exports.agriculturalAndIndustrialTyreSearchForm = exports.truckTyreSearchForm = exports.carTyreSearchForm = exports.skuSearchForm = exports.tyreSearchForm = exports.isVanCarTyre = exports.isSuvCarTyre = exports.isPassengerCarTyre = exports.calculateSeason = exports.calculateAxCategory = exports.getIdsForAxCategory = exports.seasonRules = exports.defaultSeason = exports.MAX_HITS = void 0;
const i18n_1 = require("../../common/i18n");
const ProductFilter_1 = require("../../models/ProductFilter");
const shop_1 = require("../../common/utils/shop");
exports.MAX_HITS = 250;
exports.defaultSeason = (() => {
    const currentMonth = new Date().getMonth();
    if (2 > currentMonth || 7 < currentMonth) {
        return "winter";
    }
    return "summer";
})();
exports.seasonRules = {
    summer: {
        passenger: "140",
        suv: "131",
        van: "130",
    },
    winter: {
        passenger: "149",
        suv: "138",
        van: "139",
    },
    all_season: {
        passenger: "145",
        suv: "133",
        van: "135",
    },
};
const getIdsForAxCategory = (category) => {
    const ret = [];
    Object.keys(exports.seasonRules).forEach((season) => {
        ret.push(exports.seasonRules[season][category]);
    });
    return ret;
};
exports.getIdsForAxCategory = getIdsForAxCategory;
const calculateAxCategory = (season = exports.defaultSeason, types = []) => {
    if (season !== "summer" && season !== "winter" && season !== "all_season") {
        return [
            exports.seasonRules[exports.defaultSeason].passenger,
            exports.seasonRules[exports.defaultSeason].suv,
            exports.seasonRules[exports.defaultSeason].van,
        ];
    }
    if (!types.length) {
        return [
            exports.seasonRules[season].passenger,
            exports.seasonRules[season].suv,
            exports.seasonRules[season].van,
        ];
    }
    const ret = [];
    types.forEach((type) => ret.push(exports.seasonRules[season][type]));
    return ret;
};
exports.calculateAxCategory = calculateAxCategory;
const calculateSeason = (axCategory) => {
    const season = Object.keys(exports.seasonRules).find((key) => axCategory.includes(exports.seasonRules[key].passenger) ||
        axCategory.includes(exports.seasonRules[key].suv) ||
        axCategory.includes(exports.seasonRules[key].van));
    return season || exports.defaultSeason;
};
exports.calculateSeason = calculateSeason;
const isPassengerCarTyre = (axCategory) => {
    return (axCategory.includes(exports.seasonRules.summer.passenger) ||
        axCategory.includes(exports.seasonRules.winter.passenger) ||
        axCategory.includes(exports.seasonRules.all_season.passenger));
};
exports.isPassengerCarTyre = isPassengerCarTyre;
const isSuvCarTyre = (axCategory) => {
    return (axCategory.includes(exports.seasonRules.summer.suv) ||
        axCategory.includes(exports.seasonRules.winter.suv) ||
        axCategory.includes(exports.seasonRules.all_season.suv));
};
exports.isSuvCarTyre = isSuvCarTyre;
const isVanCarTyre = (axCategory) => {
    return (axCategory.includes(exports.seasonRules.summer.van) ||
        axCategory.includes(exports.seasonRules.winter.van) ||
        axCategory.includes(exports.seasonRules.all_season.van));
};
exports.isVanCarTyre = isVanCarTyre;
exports.tyreSearchForm = Object.keys(ProductFilter_1.TyreFilters).reduce((o, key) => ({ ...o, [key]: null }), {});
exports.skuSearchForm = Object.keys(ProductFilter_1.SkuProductFilters).reduce((o, key) => ({ ...o, [key]: null }), {});
exports.carTyreSearchForm = Object.assign(Object.keys(ProductFilter_1.CarTyreFilters).reduce((o, key) => ({ ...o, [key]: null }), {}), { axCategories: (0, exports.calculateAxCategory)(), type: "carTyre" });
exports.truckTyreSearchForm = Object.assign(Object.keys(ProductFilter_1.TruckTyreFilters).reduce((o, key) => ({ ...o, [key]: null }), {}), { axCategory: ["122"], type: "truckTyre" });
exports.agriculturalAndIndustrialTyreSearchForm = Object.assign(Object.keys(ProductFilter_1.AgriculturalAndIndustrialTyreFilters).reduce((o, key) => ({ ...o, [key]: null }), {}), { axCategory: ["110", "160"], type: ["agriculturalTyre", "industrialTyre"] });
exports.forkliftTyreSearchForm = Object.assign(Object.keys(ProductFilter_1.ForkliftTyreFilters).reduce((o, key) => ({ ...o, [key]: null }), {}), { axCategory: ["150"], type: "industrialTyre" });
exports.motorcycleTyreSearchForm = Object.assign(Object.keys(ProductFilter_1.MotorcycleTyreFilters).reduce((o, key) => ({ ...o, [key]: null }), {}), { axCategory: ["170"], type: "motorcycleTyre" });
exports.spareWheelSearchForm = Object.assign(Object.keys(ProductFilter_1.SpareWheelFilters).reduce((o, key) => ({ ...o, [key]: null }), {}), { axCategory: ["910"], type: "spareWheel" });
/**
 * Product list field definitions
 */
exports.tyreProductListFields = [
    {
        key: "productName",
        value: (item) => {
            return `
                <div class="product-name cursor-pointer inline-flex items-center">
                    <div class="pr-3 flex justify-center items-center textbox textbox-right"
                        data-text="${(0, i18n_1.trans)("product_info", "Product info", "tyre_search")}">
                        <span class="icon marso-icon-info-circle text-2xl text-blue" ></span>
                    </div>
                    <div>
                        <div class="font-bold ">${item.brandName}</div>
                        <div><div>${item.treadCode}</div></div>
                    </div>
                </div>`;
        },
        label: (0, i18n_1.trans)("product_name", "Product name", "tyre_search"),
        visible: true,
        sortable: true,
        sortingFunction: (productA, productB) => {
            let brandCompare = productA.brandName.localeCompare(productB.brandName);
            if (brandCompare) {
                return brandCompare;
            }
            return productA.treadCode.localeCompare(productB.treadCode);
        },
    },
    {
        key: "size",
        value: (item) => {
            var _a;
            return `<div class="font-bold">${item.size}</div><div>${item.loadIndex}${item.speedIndex}</div><div>${(_a = item.info) !== null && _a !== void 0 ? _a : ""}</div>`;
        },
        label: (0, i18n_1.trans)("size", "Size", "tyre_search"),
        visible: true,
    },
    {
        key: "priceList",
        visible: true,
        value: (item, args) => {
            let ret = "";
            let currencyCode = item.prices.currencyCode;
            if (args.customerCurrencyCode) {
                currencyCode = args.customerCurrencyCode;
            }
            let price = item.prices.priceListNet;
            if (typeof price !== "number") {
                price = parseFloat(price);
            }
            let priceDiscountNet = item.prices.priceDiscountNet;
            if (typeof priceDiscountNet !== "number") {
                priceDiscountNet = parseFloat(priceDiscountNet);
            }
            let priceVatPercent = 0;
            if (item.prices.priceVatPercent && typeof item.prices.priceVatPercent !== "number") {
                priceVatPercent = parseFloat(item.prices.priceVatPercent);
            }
            else {
                priceVatPercent = item.prices.priceVatPercent;
            }
            if (args.showGrossPriceToggle) {
                // show gross prices
                if (priceVatPercent) {
                    ret = `${ret}<div class="font-bold">${(0, shop_1.formatPrice)(price, currencyCode, { vatPercent: priceVatPercent })}</div>`;
                }
            }
            else {
                // show net prices
                ret = `${ret}<div class="font-bold">${(0, shop_1.formatPrice)(price, currencyCode)}</div>`;
            }
            let discountPercent = (100 - priceDiscountNet / (item.prices.priceListNet / 100)) / 100;
            if (discountPercent > 0 && !args.retailPriceToggle) {
                ret = `${ret}<div class="text-red-dark hide-foreign-customer"><i class="marso-icon-promotion_gift"></i> ${(0, i18n_1.n)(discountPercent, "percent")}</div>`;
            }
            return ret;
        },
        label: (args) => {
            let ret = `<div>${(0, i18n_1.trans)("list_price", "List price", "tyre_search")}</div>`;
            if (args.showGrossPriceToggle) {
                ret = `${ret}
                    <div v-if="showGrossLabel" class="text-xs ml-1">
                        ${(0, i18n_1.trans)("gross_list_price", "gross", "tyre_search")}
                    </div>
                `;
            }
            else {
                ret = `${ret}<div v-else class="text-xs ml-1">${(0, i18n_1.trans)("net_list_price", "net", "tyre_search")}</div>`;
            }
            return ret;
        },
        sortable: true,
        sortingFunction: (productA, productB) => {
            let priceListNetA;
            if (typeof productA.prices.priceListNet !== "number") {
                priceListNetA = parseFloat(productA.prices.priceListNet);
            }
            else {
                priceListNetA = productA.prices.priceListNet;
            }
            let priceListNetB;
            if (typeof productB.prices.priceListNet !== "number") {
                priceListNetB = parseFloat(productB.prices.priceListNet);
            }
            else {
                priceListNetB = productB.prices.priceListNet;
            }
            return priceListNetA - priceListNetB;
        },
    },
    {
        key: "priceDiscount",
        visible: true,
        label: (args) => {
            let ret = `<div>${(0, i18n_1.trans)("wholesale_price", "Wholesale price", "tyre_search")}</div>`;
            if (args.showGrossPriceToggle) {
                ret = `${ret}
                    <div v-if="showGrossLabel" class="text-xs ml-1 hide-foreign-customer">
                        ${(0, i18n_1.trans)("gross_wholesale_price", "gross", "tyre_search")}
                    </div>
                `;
            }
            else {
                ret = `${ret}<div v-else class="text-xs ml-1">${(0, i18n_1.trans)("net_wholesale_price", "net", "tyre_search")}</div>`;
            }
            return ret;
        },
        value: (item, args) => {
            let price = item.prices.priceDiscountNet;
            if (typeof price !== "number") {
                price = parseFloat(price);
            }
            let currencyCode = item.prices.currencyCode;
            if (args.customerCurrencyCode) {
                currencyCode = args.customerCurrencyCode;
            }
            let ret = "<div>";
            let discountColorClass = '';
            if (item.prices.needDiscountDisplay) {
                discountColorClass = 'text-red-dark';
            }
            let priceVatPercent = 0;
            if (item.prices.priceVatPercent && typeof item.prices.priceVatPercent !== "number") {
                priceVatPercent = parseFloat(item.prices.priceVatPercent);
            }
            else {
                priceVatPercent = item.prices.priceVatPercent;
            }
            if (args.showGrossPriceToggle) {
                // show gross prices
                if (item.prices.priceEprNet) {
                    // price gross without epr
                    if (priceVatPercent) {
                        ret = `${ret}<div class="${discountColorClass}">${(0, shop_1.formatPrice)(price - item.prices.priceEprNet, currencyCode, { vatPercent: priceVatPercent })}</div>`;
                    }
                    // epr gross price
                    ret = `${ret}
                    <div class="text-sm">
                        <i class="relative top-0.5 marso-icon-recycle tooltip text-green"></i>️
                            ${(0, shop_1.formatPrice)(item.prices.priceEprNet, currencyCode, { vatPercent: priceVatPercent })}
                    </div>
                `;
                }
                // gross full price
                if (priceVatPercent) {
                    ret = `${ret}<div class="font-bold ${discountColorClass}">${(0, shop_1.formatPrice)(price, currencyCode, { vatPercent: priceVatPercent })}</div>`;
                }
            }
            else {
                // show net prices
                if (item.prices.priceEprNet) {
                    // price net without epr
                    ret = `${ret}<div class="${discountColorClass}">${(0, shop_1.formatPrice)(price - item.prices.priceEprNet, currencyCode)}</div>`;
                    // epr net price
                    ret = `${ret}
                    <div class="text-sm">
                        <i class="relative top-0.5 marso-icon-recycle tooltip text-green"></i>️
                            ${(0, shop_1.formatPrice)(item.prices.priceEprNet, currencyCode)}
                    </div>
                `;
                }
                // net full price
                ret = `${ret}<div class="font-bold ${discountColorClass}">${(0, shop_1.formatPrice)(price, currencyCode)}</div>`;
            }
            ret = `${ret}</div>`;
            return ret;
        },
        sortable: true,
        sortingFunction: (productA, productB) => {
            let priceDiscountNetA;
            if (typeof productA.prices.priceDiscountNet !== "number") {
                priceDiscountNetA = parseFloat(productA.prices.priceDiscountNet);
            }
            else {
                priceDiscountNetA = productA.prices.priceDiscountNet;
            }
            let priceDiscountNetB;
            if (typeof productB.prices.priceDiscountNet !== "number") {
                priceDiscountNetB = parseFloat(productB.prices.priceDiscountNet);
            }
            else {
                priceDiscountNetB = productB.prices.priceDiscountNet;
            }
            return priceDiscountNetA - priceDiscountNetB;
        },
    },
    {
        key: "priceRetail",
        visible: false,
        label: (args) => {
            let ret = `<div>${(0, i18n_1.trans)("retail_price", "Retail price", "tyre_search")}</div>`;
            if (args.showGrossPriceToggle) {
                ret = `${ret}
                    <div v-if="showGrossLabel" class="text-xs ml-1">
                        ${(0, i18n_1.trans)("gross_retail_price", "gross", "tyre_search")}
                    </div>
                `;
            }
            else {
                ret = `${ret}<div v-else class="text-xs ml-1">${(0, i18n_1.trans)("net_retail_price", "net", "tyre_search")}</div>`;
            }
            return ret;
        },
        value: (item, args) => {
            let ret = "";
            let price = item.prices.priceRetailNet;
            if (typeof price !== "number") {
                price = parseFloat(price);
            }
            let currencyCode = item.prices.currencyCode;
            if (args.customerCurrencyCode) {
                currencyCode = args.customerCurrencyCode;
            }
            let priceVatPercent = 0;
            if (item.prices.priceVatPercent && typeof item.prices.priceVatPercent !== "number") {
                priceVatPercent = parseFloat(item.prices.priceVatPercent);
            }
            else {
                priceVatPercent = item.prices.priceVatPercent;
            }
            if (args.showGrossPriceToggle) {
                // show gross price
                if (item.prices.priceEprNet) {
                    // price gross without epr
                    if (priceVatPercent) {
                        ret = `${ret}<div>${(0, shop_1.formatPrice)(price - item.prices.priceEprNet, currencyCode, { vatPercent: priceVatPercent })}</div>`;
                    }
                    // epr gross price
                    ret = `${ret}
                    <div class="text-sm">
                        <i class="relative top-0.5 marso-icon-recycle tooltip text-green"></i>️
                            ${(0, shop_1.formatPrice)(item.prices.priceEprNet, currencyCode, { vatPercent: priceVatPercent })}
                    </div>
                `;
                }
                if (priceVatPercent) {
                    // gross full price
                    ret = `${ret}<div class="font-bold">${(0, shop_1.formatPrice)(price, currencyCode, { vatPercent: priceVatPercent })}</div>`;
                }
            }
            else {
                // show net price
                if (item.prices.priceEprNet) {
                    // price net without epr
                    ret = `${ret}<div>${(0, shop_1.formatPrice)(price - item.prices.priceEprNet, currencyCode)}</div>`;
                    // epr net price
                    ret = `${ret}
                    <div class="text-sm">
                        <i class="relative top-0.5 marso-icon-recycle tooltip text-green"></i>️
                            ${(0, shop_1.formatPrice)(item.prices.priceEprNet, currencyCode)}
                    </div>
                `;
                }
                // net full price
                ret = `${ret}<div class="font-bold">${(0, shop_1.formatPrice)(price, currencyCode)}</div>`;
            }
            return ret;
        },
        sortable: true,
        sortingFunction: (productA, productB) => {
            let priceRetailNetA;
            if (typeof productA.prices.priceRetailNet !== "number") {
                priceRetailNetA = parseFloat(productA.prices.priceRetailNet);
            }
            else {
                priceRetailNetA = productA.prices.priceRetailNet;
            }
            let priceRetailNetB;
            if (typeof productB.prices.priceRetailNet !== "number") {
                priceRetailNetB = parseFloat(productB.prices.priceRetailNet);
            }
            else {
                priceRetailNetB = productB.prices.priceRetailNet;
            }
            return priceRetailNetA - priceRetailNetB;
        },
    },
    {
        key: "stock",
        visible: true,
        label: (0, i18n_1.trans)("stock", "Stock", "tyre_search"),
        value: (item) => {
            let cell = [];
            let stocks = item.stocks;
            let detailsCell = [];
            if ("undefined" !== typeof stocks.stockDetails) {
                stocks.stockDetails.forEach((item) => {
                    detailsCell.push(`
                        <div class="grid grid-flow-col">
                            <span class="whitespace-nowrap pr-2" title="${item.name}">${item.name}</span>
                            <span class="font-bold text-right">${item.stock}</span>
                        </div>
                    `);
                });
            }
            if ("undefined" !== typeof stocks.stockDetailsStatus) {
                if (detailsCell.length) {
                    cell.push(`<div class="${stocks.stockDetailsStatus} stock-details-box absolute p-2 bg-blue rounded min-w-full -right-4 text-white shadow z-10 cursor-pointer">${detailsCell.join("")}</div>`);
                }
                else {
                    cell.push(`<div class="${stocks.stockDetailsStatus} stock-details-box absolute p-2 bg-blue rounded min-w-full -right-4 text-white shadow z-10 text-center text-xl cursor-pointer"><span class="icon marso-icon-tyrestack"></span></div>`);
                }
            }
            if ("undefined" !== typeof stocks.stockCountry &&
                0 < stocks.stockCountry) {
                cell.push(`<div class="grid grid-flow-col">
                    <span class="show-stock-details text-blue font-bold cursor-pointer">${(0, i18n_1.trans)("country", "country", "tyre_search")}</span>
                    <span class="font-bold text-right">${stocks.stockCountry}${stocks.stockCountryMax ? "+" : ""}</span>
                </div>`);
            }
            if ("undefined" !== typeof stocks.stockLocal &&
                0 < stocks.stockLocal) {
                cell.push(`<div class="grid grid-flow-col">
                    <span>${(0, i18n_1.trans)("local", "of which local", "tyre_search")}</span>
                    <span class="font-bold text-right">${stocks.stockLocal}${stocks.stockLocalMax ? "+" : ""}</span>
                </div>`);
            }
            // Nincs szükség expressz készlet megjelenítésére, mert ez DH telephelyen egyezik a helyi készlettel.
            // if (
            //     "undefined" !== typeof stocks.stockExpress &&
            //     0 < stocks.stockExpress
            // ) {
            //     cell.push(`<div class="grid grid-flow-col">
            //         <span>${trans("express", "local", "tyre_search")}</span>
            //         <span class="font-bold text-right">${stocks.stockExpress}${
            //         stocks.stockExpressMax ? "+" : ""
            //     }</span>
            //     </div>`);
            // }
            if ("undefined" !== typeof stocks.stockManufacturer &&
                0 < stocks.stockManufacturer) {
                cell.push(`<div class="grid grid-flow-col">
                    <span>${(0, i18n_1.trans)("manufacturer", "manufacturer", "tyre_search")}</span>
                    <span class="font-bold text-right">${stocks.stockManufacturer}${stocks.stockManufacturerMax ? "+" : ""}</span>
                </div>`);
            }
            if ("undefined" !== typeof stocks.stockHun && 0 < stocks.stockHun) {
                cell.push(`<div class="grid grid-flow-col">
                    <span>${(0, i18n_1.trans)("hungarian", "hungarian", "tyre_search")}</span>
                    <span class="font-bold text-right">${stocks.stockHun}${stocks.stockHunMax ? "+" : ""}</span>
                </div>`);
            }
            if ("undefined" !== typeof stocks.status) {
                if ("limited" === stocks.status) {
                    cell.push(`<button type="button" class="button-limited">
                        ${(0, i18n_1.trans)("limited_stock", "Limited stock", "tyre_filter")}
                        <span class="tooltip"><span class="icon marso-icon-info-circle"></span></span>
                    </button>`);
                }
                else if ("orderable" === stocks.status) {
                    cell.push(`<button type="button" class="button-orderable">
                        ${(0, i18n_1.trans)("orderable", "Orderable", "tyre_filter")}
                        <span class="tooltip"><span class="icon marso-icon-info-circle"></span></span>
                    </button>`);
                }
            }
            return cell.length
                ? '<div class="relative">' + cell.join("") + "</div>"
                : "";
        },
        sortable: true,
        sortingFunction: (productA, productB) => {
            var _a, _b;
            let stockA = (_a = productA.stocks.stockCountry) !== null && _a !== void 0 ? _a : 0;
            let stockB = (_b = productB.stocks.stockCountry) !== null && _b !== void 0 ? _b : 0;
            return stockA - stockB;
        },
    },
    {
        key: "cart",
        visible: true,
        label: "",
    },
];
exports.carTyreProductListFields = [...exports.tyreProductListFields];
exports.carTyreProductListFields.splice(2, 0, {
    key: "tyreLabeling",
    visible: true,
    value: (item) => {
        let labels = "";
        if (item.fuelEfficiency) {
            labels = `${labels}<i class="marso-icon-fuel_label"></i> ${item.fuelEfficiency}<br>`;
        }
        if (item.wetGripClass) {
            labels = `${labels}<i class="marso-icon-rain_label"></i> ${item.wetGripClass}<br>`;
        }
        if (item.noiseMeasurementValue) {
            labels = `${labels}<i class="marso-icon-noise_label"></i> ${item.noiseMeasurementValue}dB`;
        }
        return labels;
    },
    label: (0, i18n_1.trans)("tyre_labeling", "Tyre labeling", "tyre_search"),
});
exports.truckTyreProductListFields = [...exports.carTyreProductListFields];
exports.agriculturalAndIndustrialTyreProductListFields = [
    ...exports.tyreProductListFields,
];
exports.forkliftTyreProductListFields = [...exports.tyreProductListFields];
exports.motorcycleTyreProductListFields = [...exports.tyreProductListFields];
exports.spareWheelProductListFields = [
    {
        key: "productName",
        value: (item) => {
            return `
                <div class="product-name cursor-pointer inline-flex items-center">
                    <div class="pr-3 flex justify-center items-center textbox textbox-right"
                        data-text="${(0, i18n_1.trans)("product_info", "Product info", "tyre_search")}">
                        <span class="icon marso-icon-info-circle text-2xl text-blue" ></span>
                    </div>
                    <div>
                        <div class="font-bold ">${item.productName}</div>
                    </div>
                </div>`;
        },
        label: (0, i18n_1.trans)("product_name", "Product name", "tyre_search"),
        visible: true,
        sortable: true
    },
    {
        key: "size",
        value: (item) => {
            var _a;
            return `<div class="font-bold">${item.size}</div><div>${(_a = item.info) !== null && _a !== void 0 ? _a : ""}</div>`;
        },
        label: (0, i18n_1.trans)("size", "Size", "tyre_search"),
        visible: true,
    },
    {
        key: "priceList",
        visible: true,
        value: (item, args) => {
            let ret = "";
            let currencyCode = item.prices.currencyCode;
            if (args.customerCurrencyCode) {
                currencyCode = args.customerCurrencyCode;
            }
            let price = item.prices.priceListNet;
            if (typeof price !== "number") {
                price = parseFloat(price);
            }
            let priceDiscountNet = item.prices.priceDiscountNet;
            if (typeof priceDiscountNet !== "number") {
                priceDiscountNet = parseFloat(priceDiscountNet);
            }
            let priceVatPercent = 0;
            if (item.prices.priceVatPercent && typeof item.prices.priceVatPercent !== "number") {
                priceVatPercent = parseFloat(item.prices.priceVatPercent);
            }
            else {
                priceVatPercent = item.prices.priceVatPercent;
            }
            if (args.showGrossPriceToggle) {
                // show gross prices
                if (priceVatPercent) {
                    ret = `${ret}<div class="font-bold">${(0, shop_1.formatPrice)(price, currencyCode, { vatPercent: priceVatPercent })}</div>`;
                }
            }
            else {
                // show net prices
                ret = `${ret}<div class="font-bold">${(0, shop_1.formatPrice)(price, currencyCode)}</div>`;
            }
            let discountPercent = (100 - priceDiscountNet / (item.prices.priceListNet / 100)) / 100;
            if (discountPercent > 0 && !args.retailPriceToggle) {
                ret = `${ret}<div class="text-red-dark hide-foreign-customer"><i class="marso-icon-promotion_gift"></i> ${(0, i18n_1.n)(discountPercent, "percent")}</div>`;
            }
            return ret;
        },
        label: (args) => {
            let ret = `<div>${(0, i18n_1.trans)("list_price", "List price", "tyre_search")}</div>`;
            if (args.showGrossPriceToggle) {
                ret = `${ret}
                    <div v-if="showGrossLabel" class="text-xs ml-1">
                        ${(0, i18n_1.trans)("gross_list_price", "gross", "tyre_search")}
                    </div>
                `;
            }
            else {
                ret = `${ret}<div v-else class="text-xs ml-1">${(0, i18n_1.trans)("net_list_price", "net", "tyre_search")}</div>`;
            }
            return ret;
        },
        sortable: true,
        sortingFunction: (productA, productB) => {
            let priceListNetA;
            if (typeof productA.prices.priceListNet !== "number") {
                priceListNetA = parseFloat(productA.prices.priceListNet);
            }
            else {
                priceListNetA = productA.prices.priceListNet;
            }
            let priceListNetB;
            if (typeof productB.prices.priceListNet !== "number") {
                priceListNetB = parseFloat(productB.prices.priceListNet);
            }
            else {
                priceListNetB = productB.prices.priceListNet;
            }
            return priceListNetA - priceListNetB;
        },
    },
    {
        key: "priceDiscount",
        visible: true,
        label: (args) => {
            let ret = `<div>${(0, i18n_1.trans)("wholesale_price", "Wholesale price", "tyre_search")}</div>`;
            if (args.showGrossPriceToggle) {
                ret = `${ret}
                    <div v-if="showGrossLabel" class="text-xs ml-1 hide-foreign-customer">
                        ${(0, i18n_1.trans)("gross_wholesale_price", "gross", "tyre_search")}
                    </div>
                `;
            }
            else {
                ret = `${ret}<div v-else class="text-xs ml-1">${(0, i18n_1.trans)("net_wholesale_price", "net", "tyre_search")}</div>`;
            }
            return ret;
        },
        value: (item, args) => {
            let price = item.prices.priceDiscountNet;
            if (typeof price !== "number") {
                price = parseFloat(price);
            }
            let currencyCode = item.prices.currencyCode;
            if (args.customerCurrencyCode) {
                currencyCode = args.customerCurrencyCode;
            }
            let ret = "<div>";
            let discountColorClass = '';
            if (item.prices.needDiscountDisplay) {
                discountColorClass = 'text-red-dark';
            }
            let priceVatPercent = 0;
            if (item.prices.priceVatPercent && typeof item.prices.priceVatPercent !== "number") {
                priceVatPercent = parseFloat(item.prices.priceVatPercent);
            }
            else {
                priceVatPercent = item.prices.priceVatPercent;
            }
            if (args.showGrossPriceToggle) {
                // show gross prices
                if (item.prices.priceEprNet) {
                    // price gross without epr
                    if (priceVatPercent) {
                        ret = `${ret}<div class="${discountColorClass}">${(0, shop_1.formatPrice)(price - item.prices.priceEprNet, currencyCode, { vatPercent: priceVatPercent })}</div>`;
                    }
                    // epr gross price
                    ret = `${ret}
                    <div class="text-sm">
                        <i class="relative top-0.5 marso-icon-recycle tooltip text-green"></i>️
                            ${(0, shop_1.formatPrice)(item.prices.priceEprNet, currencyCode, { vatPercent: priceVatPercent })}
                    </div>
                `;
                }
                // gross full price
                if (priceVatPercent) {
                    ret = `${ret}<div class="font-bold ${discountColorClass}">${(0, shop_1.formatPrice)(price, currencyCode, { vatPercent: priceVatPercent })}</div>`;
                }
            }
            else {
                // show net prices
                if (item.prices.priceEprNet) {
                    // price net without epr
                    ret = `${ret}<div class="${discountColorClass}">${(0, shop_1.formatPrice)(price - item.prices.priceEprNet, currencyCode)}</div>`;
                    // epr net price
                    ret = `${ret}
                    <div class="text-sm">
                        <i class="relative top-0.5 marso-icon-recycle tooltip text-green"></i>️
                            ${(0, shop_1.formatPrice)(item.prices.priceEprNet, currencyCode)}
                    </div>
                `;
                }
                // net full price
                ret = `${ret}<div class="font-bold ${discountColorClass}">${(0, shop_1.formatPrice)(price, currencyCode)}</div>`;
            }
            ret = `${ret}</div>`;
            return ret;
        },
        sortable: true,
        sortingFunction: (productA, productB) => {
            let priceDiscountNetA;
            if (typeof productA.prices.priceDiscountNet !== "number") {
                priceDiscountNetA = parseFloat(productA.prices.priceDiscountNet);
            }
            else {
                priceDiscountNetA = productA.prices.priceDiscountNet;
            }
            let priceDiscountNetB;
            if (typeof productB.prices.priceDiscountNet !== "number") {
                priceDiscountNetB = parseFloat(productB.prices.priceDiscountNet);
            }
            else {
                priceDiscountNetB = productB.prices.priceDiscountNet;
            }
            return priceDiscountNetA - priceDiscountNetB;
        },
    },
    {
        key: "priceRetail",
        visible: false,
        label: (args) => {
            let ret = `<div>${(0, i18n_1.trans)("retail_price", "Retail price", "tyre_search")}</div>`;
            if (args.showGrossPriceToggle) {
                ret = `${ret}
                    <div v-if="showGrossLabel" class="text-xs ml-1">
                        ${(0, i18n_1.trans)("gross_retail_price", "gross", "tyre_search")}
                    </div>
                `;
            }
            else {
                ret = `${ret}<div v-else class="text-xs ml-1">${(0, i18n_1.trans)("net_retail_price", "net", "tyre_search")}</div>`;
            }
            return ret;
        },
        value: (item, args) => {
            let ret = "";
            let price = item.prices.priceRetailNet;
            if (typeof price !== "number") {
                price = parseFloat(price);
            }
            let currencyCode = item.prices.currencyCode;
            if (args.customerCurrencyCode) {
                currencyCode = args.customerCurrencyCode;
            }
            let priceVatPercent = 0;
            if (item.prices.priceVatPercent && typeof item.prices.priceVatPercent !== "number") {
                priceVatPercent = parseFloat(item.prices.priceVatPercent);
            }
            else {
                priceVatPercent = item.prices.priceVatPercent;
            }
            if (args.showGrossPriceToggle) {
                // show gross price
                if (item.prices.priceEprNet) {
                    // price gross without epr
                    if (priceVatPercent) {
                        ret = `${ret}<div>${(0, shop_1.formatPrice)(price - item.prices.priceEprNet, currencyCode, { vatPercent: priceVatPercent })}</div>`;
                    }
                    // epr gross price
                    ret = `${ret}
                    <div class="text-sm">
                        <i class="relative top-0.5 marso-icon-recycle tooltip text-green"></i>️
                            ${(0, shop_1.formatPrice)(item.prices.priceEprNet, currencyCode, { vatPercent: priceVatPercent })}
                    </div>
                `;
                }
                if (priceVatPercent) {
                    // gross full price
                    ret = `${ret}<div class="font-bold">${(0, shop_1.formatPrice)(price, currencyCode, { vatPercent: priceVatPercent })}</div>`;
                }
            }
            else {
                // show net price
                if (item.prices.priceEprNet) {
                    // price net without epr
                    ret = `${ret}<div>${(0, shop_1.formatPrice)(price - item.prices.priceEprNet, currencyCode)}</div>`;
                    // epr net price
                    ret = `${ret}
                    <div class="text-sm">
                        <i class="relative top-0.5 marso-icon-recycle tooltip text-green"></i>️
                            ${(0, shop_1.formatPrice)(item.prices.priceEprNet, currencyCode)}
                    </div>
                `;
                }
                // net full price
                ret = `${ret}<div class="font-bold">${(0, shop_1.formatPrice)(price, currencyCode)}</div>`;
            }
            return ret;
        },
        sortable: true,
        sortingFunction: (productA, productB) => {
            let priceRetailNetA;
            if (typeof productA.prices.priceRetailNet !== "number") {
                priceRetailNetA = parseFloat(productA.prices.priceRetailNet);
            }
            else {
                priceRetailNetA = productA.prices.priceRetailNet;
            }
            let priceRetailNetB;
            if (typeof productB.prices.priceRetailNet !== "number") {
                priceRetailNetB = parseFloat(productB.prices.priceRetailNet);
            }
            else {
                priceRetailNetB = productB.prices.priceRetailNet;
            }
            return priceRetailNetA - priceRetailNetB;
        },
    },
    {
        key: "stock",
        visible: true,
        label: (0, i18n_1.trans)("stock", "Stock", "tyre_search"),
        value: (item) => {
            let cell = [];
            let stocks = item.stocks;
            let detailsCell = [];
            if ("undefined" !== typeof stocks.stockDetails) {
                stocks.stockDetails.forEach((item) => {
                    detailsCell.push(`
                        <div class="grid grid-flow-col">
                            <span class="whitespace-nowrap pr-2" title="${item.name}">${item.name}</span>
                            <span class="font-bold text-right">${item.stock}</span>
                        </div>
                    `);
                });
            }
            if ("undefined" !== typeof stocks.stockDetailsStatus) {
                if (detailsCell.length) {
                    cell.push(`<div class="${stocks.stockDetailsStatus} stock-details-box absolute p-2 bg-blue rounded min-w-full -right-4 text-white shadow z-10 cursor-pointer">${detailsCell.join("")}</div>`);
                }
                else {
                    cell.push(`<div class="${stocks.stockDetailsStatus} stock-details-box absolute p-2 bg-blue rounded min-w-full -right-4 text-white shadow z-10 text-center text-xl cursor-pointer"><span class="icon marso-icon-tyrestack"></span></div>`);
                }
            }
            if ("undefined" !== typeof stocks.stockCountry &&
                0 < stocks.stockCountry) {
                cell.push(`<div class="grid grid-flow-col">
                    <span class="show-stock-details text-blue font-bold cursor-pointer">${(0, i18n_1.trans)("country", "country", "tyre_search")}</span>
                    <span class="font-bold text-right">${stocks.stockCountry}${stocks.stockCountryMax ? "+" : ""}</span>
                </div>`);
            }
            if ("undefined" !== typeof stocks.stockLocal &&
                0 < stocks.stockLocal) {
                cell.push(`<div class="grid grid-flow-col">
                    <span>${(0, i18n_1.trans)("local", "of which local", "tyre_search")}</span>
                    <span class="font-bold text-right">${stocks.stockLocal}${stocks.stockLocalMax ? "+" : ""}</span>
                </div>`);
            }
            if ("undefined" !== typeof stocks.stockManufacturer &&
                0 < stocks.stockManufacturer) {
                cell.push(`<div class="grid grid-flow-col">
                    <span>${(0, i18n_1.trans)("manufacturer", "manufacturer", "tyre_search")}</span>
                    <span class="font-bold text-right">${stocks.stockManufacturer}${stocks.stockManufacturerMax ? "+" : ""}</span>
                </div>`);
            }
            if ("undefined" !== typeof stocks.stockHun && 0 < stocks.stockHun) {
                cell.push(`<div class="grid grid-flow-col">
                    <span>${(0, i18n_1.trans)("hungarian", "hungarian", "tyre_search")}</span>
                    <span class="font-bold text-right">${stocks.stockHun}${stocks.stockHunMax ? "+" : ""}</span>
                </div>`);
            }
            if ("undefined" !== typeof stocks.status) {
                if ("limited" === stocks.status) {
                    cell.push(`<button type="button" class="button-limited">
                        ${(0, i18n_1.trans)("limited_stock", "Limited stock", "tyre_filter")}
                        <span class="tooltip"><span class="icon marso-icon-info-circle"></span></span>
                    </button>`);
                }
                else if ("orderable" === stocks.status) {
                    cell.push(`<button type="button" class="button-orderable">
                        ${(0, i18n_1.trans)("orderable", "Orderable", "tyre_filter")}
                        <span class="tooltip"><span class="icon marso-icon-info-circle"></span></span>
                    </button>`);
                }
            }
            return cell.length
                ? '<div class="relative">' + cell.join("") + "</div>"
                : "";
        },
        sortable: true,
        sortingFunction: (productA, productB) => {
            var _a, _b;
            let stockA = (_a = productA.stocks.stockCountry) !== null && _a !== void 0 ? _a : 0;
            let stockB = (_b = productB.stocks.stockCountry) !== null && _b !== void 0 ? _b : 0;
            return stockA - stockB;
        },
    },
    {
        key: "cart",
        visible: true,
        label: "",
    },
];
const getQueryParams = (form, route = null) => {
    let params = Object.assign({}, Object.fromEntries(Object.entries(form).filter(([prop, value]) => {
        if (typeof value === "boolean") {
            return value;
        }
        return value !== null;
    })), route ? route.query : {});
    if (params.itemsPerPage) {
        delete params.itemsPerPage;
    }
    if (params.axCategory) {
        params.axCategories = params.axCategory;
        delete params.axCategory;
    }
    if (params.homologatedNotation) {
        params.homologatedNotations = params.homologatedNotation;
        delete params.homologatedNotation;
    }
    return params;
};
exports.getQueryParams = getQueryParams;
