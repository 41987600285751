"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const i18n_1 = require("../../common/i18n");
class CartProduct {
    constructor(id, ean, name, axType, axCategory, diameter, priceNet, priceEpr, priceEco, priceListNet, quantity, weight, manufacturerSku, priceVatPercent, currencyCode, stockSite, stockCentral, stockCountry, stockWholesaleSite, stockExpress, brandName, treadCode, fuelEfficiency, wetGripClass, noiseMeasurementValue, size, loadIndex, speedIndex, info, stockInfo) {
        this.id = id;
        this.ean = ean;
        this.name = name;
        this.axType = axType;
        this.axCategory = axCategory;
        this.diameter = diameter;
        this.priceNet = priceNet;
        this.priceEpr = priceEpr;
        this.priceEco = priceEco;
        this.priceListNet = priceListNet;
        this.quantity = quantity;
        this.weight = weight;
        this.manufacturerSku = manufacturerSku;
        this.priceVatPercent = priceVatPercent;
        this.currencyCode = currencyCode;
        this.stockSite = stockSite;
        this.stockCentral = stockCentral;
        this.stockCountry = stockCountry;
        this.stockWholesaleSite = stockWholesaleSite;
        this.stockExpress = stockExpress;
        this.brandName = brandName;
        this.treadCode = treadCode;
        this.fuelEfficiency = fuelEfficiency;
        this.wetGripClass = wetGripClass;
        this.noiseMeasurementValue = noiseMeasurementValue;
        this.size = size;
        this.loadIndex = loadIndex;
        this.speedIndex = speedIndex;
        this.info = info;
        this.stockInfo = stockInfo;
    }
    getProductName() {
        if (this.brandName !== '' && this.treadCode !== '') {
            return `<div class="font-bold">${this.brandName}</div>
                    <div class="font-normal">${this.treadCode}</div>
                    <div class="font-normal text-xs">${i18n_1.Translator.trans('sku', 'SKU', 'carts')}: ${this.id}</div>`;
        }
        return `<div class="font-bold">${this.name}</div>`;
    }
    getDetailsSize() {
        return `<div class="font-bold">${this.size}</div>
                <div class="font-normal">${this.loadIndex}${this.speedIndex}</div>
                <div class="font-normal">${this.info}</div>`;
    }
}
exports.default = CartProduct;
